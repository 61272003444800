import { cloneDeep } from 'lodash';

import { hDomainsRepo } from '@/repositories';
import { useDomainManagementStore } from '@/stores';
import { DOMAIN_REGISTRAR } from '@/types';

export default {
  state: {
    hDomainWhoisProfileTypes: {
      ownerWhoisProfile: {
        slug: 'owner',
        label: 'registrant',
        disabled: false,
      },
      adminWhoisProfile: {
        slug: 'admin',
        label: 'administrative',
        disabled: false,
      },
      billingWhoisProfile: {
        slug: 'billing',
        label: 'billing',
        disabled: false,
      },
      techWhoisProfile: {
        slug: 'tech',
        label: 'technical',
        disabled: false,
      },
    },
    hDomainWhoisProfiles: [],
    hDomainWhoisProfilesUsage: {},
    hDomainWhoisProfilesLoading: true,
    disableWhoIsProfileEditing: false,
    whoIsProfileToEdit: {},
    performingWhoIsAction: false,
    whoIsManagmentStep: 1,
    hDomainWhoisProfilePreUpdate: null,
    tldSettings: {},
    whoIsLtAddressOptions: [],
    whoIsLtAddressRaw: [],
    oneClickWhoIsProfile: null,
  },
  getters: {
    getHDomainWhoisProfileTypes: (state) => (domain) => {
      const profileTypes = cloneDeep(state.hDomainWhoisProfileTypes);

      const { getDomainData } = useDomainManagementStore();
      const domainDetails = getDomainData(domain) || {};

      Object.keys(profileTypes).forEach((key) => {
        if (!domainDetails[key]) delete profileTypes[key];
        if (profileTypes[key]) {
          profileTypes[key].name = key;
        }
      });

      if (
        !domainDetails.tldSettings?.isModifyOwnerSupported &&
        profileTypes.ownerWhoisProfile
      ) {
        profileTypes.ownerWhoisProfile.disabled = true;
      }

      if (
        domainDetails.registrar === DOMAIN_REGISTRAR.REGISTRO_BR &&
        profileTypes.adminWhoisProfile
      ) {
        profileTypes.adminWhoisProfile.disabled = true;
      }

      return profileTypes;
    },
    getHDomainWhoisProfiles(state) {
      return state.hDomainWhoisProfiles;
    },
    getHDomainWhoisProfilesIds(state) {
      return state.hDomainWhoisProfiles.map((profile) => profile.id);
    },
    getHDomainWhoisProfileUsageArray: (state) =>
      state.hDomainWhoisProfilesUsage,
    getHDomainWhoisProfileUsage: (state) => (id) =>
      state.hDomainWhoisProfilesUsage[id],
    getHDomainWhoisProfilesLoading: (state) =>
      state.hDomainWhoisProfilesLoading,
    getHDomainWhoisProfilePreUpdate: (state) =>
      state.hDomainWhoisProfilePreUpdate,
    getTldAllowedCountries: (state) => (tld) =>
      state.tldSettings[tld]?.allowedCountries,
    getTldSettings: (state) => (tld) => state.tldSettings[tld],
  },
  mutations: {
    SET_ONE_CLICK_WHOIS_PROFILE(state, data) {
      state.oneClickWhoIsProfile = data;
    },
    SET_DISABLE_WHOIS_PROFILE_EDITING(state, data) {
      state.disableWhoIsProfileEditing = data;
    },
    SET_PERFORMING_WHOIS_ACTION(state, data) {
      state.performingWhoIsAction = data;
    },
    SET_WHOIS_PROFILE_TO_EDIT(state, data) {
      state.whoIsProfileToEdit = data;
    },
    SET_WHOIS_MANAGEMENT_STEP(state, data) {
      state.whoIsManagmentStep = data;
    },
    ADD_HDOMAIN_WHOIS_PROFILE(state, data) {
      state.hDomainWhoisProfiles = [...state.hDomainWhoisProfiles, data];
    },
    SET_HDOMAIN_WHOIS_PROFILES(state, data) {
      state.hDomainWhoisProfiles = data;
    },
    REMOVE_HDOMAIN_WHOIS_PROFILE(state, data) {
      state.hDomainWhoisProfiles = state.hDomainWhoisProfiles.filter(
        ({ id }) => id !== data,
      );
    },
    ADD_HDOMAINS_WHOIS_PROFILES_USAGE(state, data) {
      state.hDomainWhoisProfilesUsage = data;
    },
    setHDomainWhoisProfilesLoading(state, data) {
      state.hDomainWhoisProfilesLoading = data;
    },
    SET_HDOMAIN_WHOIS_PROFILE_PRE_UPDATE(state, data = null) {
      state.hDomainWhoisProfilePreUpdate = data;
    },
    SET_TLD_SETTING(state, settings) {
      state.tldSettings[settings.tld] = settings;
    },
    SET_WHOIS_LT_ADDRESS_OPTIONS(state, options) {
      state.whoIsLtAddressOptions = options;
    },
    SET_WHOIS_LT_ADDRESS_RAW(state, options) {
      state.whoIsLtAddressRaw = options;
    },
  },
  actions: {
    async fetchAllowedCountries({ commit }, tld) {
      const [{ data }] = await hDomainsRepo.getTldSettings(tld);
      commit('SET_TLD_SETTING', data);
    },
    async fetchWhoisProfiles({ commit }, payload) {
      const [{ data }] = await hDomainsRepo.getWhoises(payload);

      commit('SET_HDOMAIN_WHOIS_PROFILES', data || []);
      commit('setHDomainWhoisProfilesLoading', false);
    },
    async fetchWhoisUsage({ commit, getters }) {
      if (!getters.getHDomainWhoisProfilesIds.length) return;
      const [{ data }, err] = await hDomainsRepo.getWhoisUsage(
        getters.getHDomainWhoisProfilesIds,
      );

      if (!err) commit('ADD_HDOMAINS_WHOIS_PROFILES_USAGE', data);
    },
    async storeWhoisProfile({ commit }, whoisData) {
      const [{ data }, err] = await hDomainsRepo.storeWhoisProfile(whoisData);

      if (!err) commit('ADD_HDOMAIN_WHOIS_PROFILE', data);

      return [{ data }, err];
    },
    async whoisProfileDestroy({ commit }, whoisId) {
      const [, err] = await hDomainsRepo.deleteWhois(whoisId);
      if (!err) commit('REMOVE_HDOMAIN_WHOIS_PROFILE', whoisId);
    },
    async whoisProfileUpdate({ commit }, whoisData) {
      commit('SET_PERFORMING_WHOIS_ACTION', true);
      commit('SET_DISABLE_WHOIS_PROFILE_EDITING', true);

      const [{ data }, err] = await hDomainsRepo.changeWhoisProfile(whoisData);

      commit('SET_WHOIS_MANAGEMENT_STEP', 1);
      commit('SET_HDOMAIN_WHOIS_PROFILE_PRE_UPDATE', whoisData);
      commit('SET_PERFORMING_WHOIS_ACTION', false);

      return [{ data }, err];
    },
    whoisProfileUpdateSave({ commit, getters }) {
      const data = getters.getHDomainWhoisProfilePreUpdate;
      if (!data) return;

      const { setDomainDataProperty } = useDomainManagementStore();

      const whoisProfile = getters.getHDomainWhoisProfiles.find(
        ({ id }) => id === data.newWhoisId,
      );

      const map = {
        owner: 'ownerWhoisProfile',
        admin: 'adminWhoisProfile',
        billing: 'billingWhoisProfile',
        tech: 'techWhoisProfile',
      };

      if (whoisProfile) {
        data.changeFor.forEach((whoisProfileID) => {
          setDomainDataProperty({
            field: map[whoisProfileID],
            value: whoisProfile,
            domain: data.domain,
          });
        });
      }

      commit('SET_HDOMAIN_WHOIS_PROFILE_PRE_UPDATE');
    },
  },
};
