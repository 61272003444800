import { WHOIS_FIELD_VALUE_PLACEHOLDER } from '@/data/globalConstants';
import { useProfileStore } from '@/stores';
import type { IWhoIsField, WhoIsProfile, WhoIsDetail } from '@/types';
import { WHO_IS_FIELD } from '@/types';
import {
  isZipWhoIsField,
  isStateWhoIsField,
} from '@/utils/helpers/domainsHelper';
import { snakeToCamelObj } from '@/utils/services/namingConventionsService';

type PrefillValues = {
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  phoneCc: string;
  phoneNumber: string;
  address: string;
  city: string;
  zip: string;
  state: string;
  companyName: string;
};

export const useRegistrationFormPrefills = () => {
  const profileStore = useProfileStore();

  const getZipWithPrefix = (countryCode: string, zip: string) => {
    const zipPrefixes = {
      LT: 'LT-',
      AZ: 'AZ ',
      LU: 'L-',
      MD: 'MD-',
    };

    const prefix = zipPrefixes[countryCode as keyof typeof zipPrefixes];

    if (!prefix) {
      return zip;
    }

    return zip.startsWith(prefix) ? zip : `${prefix}${zip}`;
  };

  const getDefaultAccountValues = (country: string): PrefillValues => {
    const accountCountry = profileStore.contact?.countryCode || '';
    const rawFirstName = profileStore.contact?.firstName || '';
    const lastName = profileStore.contact?.lastName || '';
    const email = profileStore.contact?.email || '';
    const phoneCc = profileStore.contact?.phoneCc || '';
    const phoneNumber = profileStore.contact?.phone || '';
    const address1 = profileStore.contact?.address || '';
    const city = profileStore.contact?.city || '';
    const zip = profileStore.contact?.zip || '';
    const state = profileStore.contact?.state || '';
    const companyName = profileStore.contact?.companyName || '';

    const isClientCountrySelected = country === accountCountry;
    const firstName =
      rawFirstName && !rawFirstName.includes('@') ? rawFirstName : '';
    const getAddressField = (field: string) =>
      isClientCountrySelected ? field : '';
    const zipWithPrefix = getZipWithPrefix(accountCountry, zip);

    return {
      firstName,
      lastName,
      email,
      countryCode: country || '',
      phoneCc,
      phoneNumber,
      address: getAddressField(address1),
      city: getAddressField(city),
      zip: getAddressField(zipWithPrefix),
      state: getAddressField(state),
      companyName,
    };
  };

  const getOneClickWhoIsProfileValues = ({
    prefillProfile,
    country,
  }: {
    prefillProfile: WhoIsProfile | null;
    country: string;
  }) => {
    if (!prefillProfile) {
      return null;
    }

    const result: PrefillValues = {} as PrefillValues;
    const profileCountry = prefillProfile.country;
    const isProfileCountrySelected = country === profileCountry;
    const details = {
      ...prefillProfile.whoisDetails,
      ...prefillProfile.tldDetails,
    } as { [key: string]: WhoIsDetail };

    Object.values(details)?.forEach(({ value, slug }: WhoIsDetail) => {
      const cannotBePrefilled =
        (isCountryDependantField(slug) && !isProfileCountrySelected) ||
        value === WHOIS_FIELD_VALUE_PLACEHOLDER;

      result[slug as keyof typeof result] = cannotBePrefilled ? '' : value;
    });

    return result;
  };

  const isCountryDependantField = (fieldSlug: string) => {
    const countryDependantFields: string[] = [
      WHO_IS_FIELD.ADDRESS,
      WHO_IS_FIELD.CITY,
    ];

    return (
      countryDependantFields.includes(fieldSlug) ||
      isZipWhoIsField(fieldSlug) ||
      isStateWhoIsField(fieldSlug)
    );
  };

  const getValidationRegex = (regularExpression: string) => {
    const flags = regularExpression.replace(/.*\/([gimyu]*)$/, '$1');
    const source = regularExpression.replace(
      new RegExp(`^/(.*?)/${flags}$`),
      '$1',
    );

    return new RegExp(source, flags);
  };

  const getRegularAccountPrefills = ({
    prefillProfile,
    country,
    whoIsFields,
  }: {
    prefillProfile: WhoIsProfile | null;
    country: string;
    whoIsFields: IWhoIsField[];
  }) => {
    const defaultValues =
      getOneClickWhoIsProfileValues({
        prefillProfile,
        country,
      }) || getDefaultAccountValues(country);

    const result: { [key: string]: string } = {};

    whoIsFields.forEach(({ slug, regularExpression }: IWhoIsField) => {
      const pattern = getValidationRegex(regularExpression);
      const defaultValue = defaultValues[slug as keyof typeof defaultValues];

      if (defaultValue && pattern.test(defaultValue)) {
        result[slug] = defaultValue || '';

        return;
      }

      if (isStateWhoIsField(slug) && pattern.test(defaultValues.state)) {
        result[slug] = defaultValues.state;

        return;
      }

      if (isZipWhoIsField(slug) && pattern.test(defaultValues.zip)) {
        result[slug] = defaultValues.zip;
      }
    });

    return result;
  };

  const getAdditionalDetailsPrefills = ({
    prefillProfile,
    country,
    tldFields,
  }: {
    prefillProfile: WhoIsProfile | null;
    country: string;
    tldFields: IWhoIsField[];
  }) => {
    const defaultValues = snakeToCamelObj(
      getOneClickWhoIsProfileValues({
        prefillProfile,
        country,
      }) || {},
    );

    const result: { [key: string]: string } = {};

    tldFields.forEach(({ slug, regularExpression, prefill }: IWhoIsField) => {
      const pattern = getValidationRegex(regularExpression);
      const defaultValue =
        defaultValues[slug as keyof typeof defaultValues] || prefill;

      if (defaultValue && pattern.test(defaultValue)) {
        result[slug] = defaultValue || '';
      }
    });

    return result;
  };

  const getLithuanianAccountPrefills = ({
    prefillProfile,
    country,
    whoIsFields,
  }: {
    prefillProfile: WhoIsProfile | null;
    country: string;
    whoIsFields: IWhoIsField[];
  }) => {
    const defaultValues =
      getOneClickWhoIsProfileValues({
        prefillProfile,
        country,
      }) || getDefaultAccountValues(country);
    const result: { [key: string]: string } = {};
    const LT_PREFILL_FIELDS: string[] = [
      WHO_IS_FIELD.FIRST_NAME,
      WHO_IS_FIELD.LAST_NAME,
      WHO_IS_FIELD.EMAIL,
      WHO_IS_FIELD.PHONE_CC,
      WHO_IS_FIELD.PHONE_NUMBER,
      WHO_IS_FIELD.COMPANY,
    ];

    whoIsFields.forEach(({ slug, regularExpression }) => {
      if (!LT_PREFILL_FIELDS.includes(slug)) {
        return;
      }

      const pattern = getValidationRegex(regularExpression);
      const defaultValue = defaultValues[slug as keyof typeof defaultValues];

      if (defaultValue && pattern.test(defaultValue)) {
        result[slug] = defaultValue || '';

        return;
      }
    });

    return result;
  };

  return {
    getRegularAccountPrefills,
    getLithuanianAccountPrefills,
    getAdditionalDetailsPrefills,
  };
};
