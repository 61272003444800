<script setup lang="ts">
//JSON-slugs-migrated
import { computed, watch } from 'vue';

import BulkActionDomainsList from './BulkActionDomainsList.vue';

import Domain from '@/components/Domain.vue';
import HCircleLoader from '@/components/HCircleLoader.vue';
import HProgress from '@/components/HProgress/HProgress.vue';
import { useGlobals, useModal, useDynamicDomainText } from '@/composables';
import { useDomainBulkActionsStore } from '@/stores/domainBulkActionsStore';
import { DOMAIN_BULK_ACTION_STATUS } from '@/types';

interface Emits {
  (eventName: 'close'): void;
}

const emits = defineEmits<Emits>();

const store = useDomainBulkActionsStore();

const { t } = useGlobals();
const { openModal } = useModal();
const { getFailedBulkActionText, getSuccessfulBulkActionText } =
  useDynamicDomainText();

const percentsCompleted = computed(() => {
  const percents =
    (100 / bulkActionProgressListAmount.value) *
    finishedBulkActionsAmount.value;

  return Math.floor(percents);
});

const finishedBulkActionsAmount = computed(
  () => store.finishedBulkActions.length,
);

const bulkActionProgressListAmount = computed(
  () => store.bulkActionProgressList.length,
);

const successfulBulkActionsAmount = computed(
  () => store.successfulBulkActions.length,
);

const headerText = computed(() =>
  store.isBulkActionCompleted
    ? 'v2.bulk.actions.summary'
    : 'v2.bulk.actions.in.progress',
);

const subheaderText = computed(() =>
  store.isBulkActionCompleted
    ? 'v2.the.bulk.actions.have.been.finished.exclamation.you.can.view.a.summary.of.what.was.done'
    : 'v2.feel.free.to.close.this.overlay.well.send.you.a.notification.once.the.actions.have',
);

const showActionSuccessfulList = () => {
  openDomainBulkActionList(
    store.successfulBulkActions.map(({ domain }) => domain),
    'v2.successful.bulk.actions',
    'v2.domains.that.completed.the.actions.successfully',
  );
};

const showActionFailedList = () => {
  openDomainBulkActionList(
    store.failedBulkActions.map(({ domain }) => domain),
    'v2.failed.bulk.actions',
    'v2.domains.that.failed.to.complete.the.actions',
  );
};

const openDomainBulkActionList = (
  domains: string[],
  title: string,
  subtitle: string,
) => {
  openModal({
    component: { BulkActionDomainsList },
    data: {
      title: t(title),
      subtitle: t(subtitle),
      domains,
    },
    steps: [
      {
        hideX: true,
        goBackText: 'v2.bulk.actions.summary',
        goBackCustom: async () =>
          openModal({
            component: {
              BulkActionProgressModal: (
                await import(
                  '@/components/Modals/HModal/HDomains/BulkActionProgressModal.vue'
                )
              ).default,
            },
          }),
      },
    ],
  });
};

watch(
  () => store.isBulkActionCompleted,
  (isCompleted) => {
    if (isCompleted) {
      store.deleteBulkAction();
    }
  },
  { immediate: true },
);
</script>

<template>
  <h2 v-trans class="h-mb-8">{{ headerText }}</h2>
  <p v-trans class="h-mb-8">{{ subheaderText }}</p>

  <div class="action-progress__info">
    <div class="d-flex justify-content-between">
      <span>
        {{ finishedBulkActionsAmount }}/{{ bulkActionProgressListAmount }}
      </span>
      <span>{{ percentsCompleted }}%</span>
    </div>
    <HProgress
      lg
      primary
      :value="percentsCompleted"
      class="action-progress__bar"
    />
  </div>
  <template v-if="store.isBulkActionCompleted">
    <div v-if="successfulBulkActionsAmount" class="completed-detail">
      <div class="d-flex align-items-center">
        <HpIcon icon="icon-check" success class="h-mr-12" />
        <span
          v-safe-html="getSuccessfulBulkActionText(successfulBulkActionsAmount)"
        />
      </div>
      <HButton v-qa-generate variant="text" @click="showActionSuccessfulList">
        {{ t('v2.see.list') }}
      </HButton>
    </div>
    <div v-if="store.failedBulkActions.length" class="completed-detail">
      <div class="d-flex align-items-center">
        <HpIcon icon="icon-close" danger class="h-mr-12" />
        <span
          v-safe-html="getFailedBulkActionText(store.failedBulkActions.length)"
        />
      </div>
      <HButton v-qa-generate variant="text" @click="showActionFailedList">
        {{ t('v2.see.list') }}
      </HButton>
    </div>
  </template>
  <div v-else class="domains-list">
    <div class="domains-list__header">
      <div v-trans>v2.domain</div>
      <div v-trans>v2.status</div>
    </div>
    <div class="domains-list__content">
      <div
        v-for="item in store.bulkActionProgressList"
        :key="item.domain"
        class="domains-list__row"
      >
        <Domain is-strict-domain :domain="item.domain" tag="div" />
        <div class="d-flex align-items-center">
          <HCircleLoader
            v-if="item.status === DOMAIN_BULK_ACTION_STATUS.PROCESSING"
            dimensions="20px"
            class="h-mr-8"
          />
          <HpIcon
            v-else-if="item.icon"
            :key="item.status"
            :icon="item.icon.name"
            :color="item.icon.color"
            class="h-mr-8"
          />
          <span v-trans>{{ item.status }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="domains-list__actions">
    <HButton v-qa-generate variant="outline" @click="emits('close')">
      {{ t('v2.close') }}
    </HButton>
  </div>
</template>

<style scoped lang="scss">
.domains-list {
  border: 1px solid var(--gray-border);
  border-radius: 4px;
  width: 100%;

  &__header {
    display: flex;
    border-bottom: 1px solid var(--gray-border);
    padding: 8px 0;
    margin: 0 24px;
    color: var(--secondaryText);

    & > div {
      width: 100%;
    }
  }

  &__content {
    max-height: 165px;
    overflow-y: auto;
    padding: 8px 0 0 24px;
  }

  &__row {
    display: flex;
    margin-bottom: 8px;

    & > div {
      width: 100%;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
}

.action-progress {
  &__info {
    margin: 24px 0;
    font-size: 12px;
    color: var(--gray);
  }

  &__bar {
    border-radius: 8px;
    background-color: var(--white-blue);
  }
}

.completed-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  button {
    width: max-content;
    white-space: nowrap;
  }
}
</style>
