import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { ref } from 'vue';

import { hDomainsRepo } from '@/repositories';
import { DNS_TYPE } from '@/types';
import type { IHDnsZone, IHDomainDnsItem } from '@/types';

export const useDnsManagementStore = defineStore('dnsManagementStore', () => {
  const dnsRecordsDictionary = ref<Record<string, IHDnsZone[]>>({});

  const getDomainDnsRecords = (domain: string): IHDnsZone[] | undefined =>
    dnsRecordsDictionary.value[domain];

  const getFormattedDnsZonePayload = (dnsZone: IHDnsZone[]) =>
    cloneDeep(dnsZone).map((item) => {
      item.records = item.records.map((record) => {
        delete record.id;

        return record;
      });

      return item;
    });

  const getDnsRecordsList = (domain: string) => {
    const domainDnsRecord = dnsRecordsDictionary.value[domain];

    if (!domainDnsRecord) return undefined;

    const records: IHDomainDnsItem[] = [];

    domainDnsRecord.forEach((recordType) => {
      recordType.records.forEach((record) => {
        const formattedRecord = {
          id: record.id ?? '',
          name: recordType.name,
          type: recordType.type,
          ttl: recordType.ttl,
          content: record.content,
          priority: record.properties?.priority,
        };

        records.push(formattedRecord);
      });
    });

    return records;
  };

  const setDomainDnsRecords = (domain: string, records: IHDnsZone[]) => {
    const recordsWithUniqueId = records.map((record) => {
      record.records.forEach((item) => {
        item.id = uuidv4();
      });

      return record;
    });

    dnsRecordsDictionary.value[domain] = recordsWithUniqueId;
  };

  const addDomainDnsRecord = (domain: string, record: IHDnsZone) => {
    const type = record.type;

    const domainDnsRecords = getDomainDnsRecords(domain);

    if (!domainDnsRecords) return;

    const existingZone = domainDnsRecords.find(
      (item) => item.type === type && item.name === record.name,
    );

    if (!existingZone) {
      domainDnsRecords.push(record);

      return;
    }

    existingZone.records.push(record.records[0]);
    existingZone.ttl = record.ttl;
  };

  const clearDnsRecordsDictionary = () => {
    dnsRecordsDictionary.value = {};
  };

  const removeDomainDnsRecord = (domain: string, id: string) => {
    const domainDnsRecords = getDomainDnsRecords(domain);

    if (!domainDnsRecords) return;

    domainDnsRecords.forEach((record) => {
      record.records = record.records.filter((item) => item.id !== id);
    });

    dnsRecordsDictionary.value[domain] = domainDnsRecords.filter(
      (record) => !!record.records.length,
    );
  };

  const removeDomainCnameRecordsExceptWithId = (domain: string, id: string) => {
    const domainDnsRecords = getDomainDnsRecords(domain);

    if (!domainDnsRecords) return;

    domainDnsRecords.forEach((record) => {
      const isCnameRecord = record.type === DNS_TYPE.CNAME;
      const isCurrentDomainUsed = record.name === '@' || record.name === domain;

      if (isCnameRecord && isCurrentDomainUsed) {
        record.records = record.records.filter((item) => item.id === id);
      }
    });

    dnsRecordsDictionary.value[domain] = domainDnsRecords.filter(
      (record) => !!record.records.length,
    );
  };

  const fetchDomainDnsRecords = async (domain: string) => {
    const [{ data }, err] = await hDomainsRepo.getDnsZone(domain);

    if (err) {
      setDomainDnsRecords(domain, []);

      return;
    }

    setDomainDnsRecords(domain, data.dnsZone);
  };

  return {
    fetchDomainDnsRecords,
    getDomainDnsRecords,
    addDomainDnsRecord,
    removeDomainDnsRecord,
    clearDnsRecordsDictionary,
    getDnsRecordsList,
    removeDomainCnameRecordsExceptWithId,
    setDomainDnsRecords,
    dnsRecordsDictionary,
    getFormattedDnsZonePayload,
  };
});
