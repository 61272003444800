<template>
  <div v-if="isVisible" class="domain-status-banner">
    <div class="h-container">
      <Component :is="activeBanner" />
    </div>
  </div>
</template>

<script>
import {
  HDOMAIN_RESOURCE_TYPES,
  HDOMAIN_RESOURCE_STATUSES,
} from '@hostinger/hdomains-status';
import { storeToRefs } from 'pinia';
import { mapActions, mapGetters } from 'vuex';

import DomainInactiveBanner from './DomainInactiveBanner';
import DomainNotPaidBanner from './DomainNotPaidBanner';
import DomainNotPointingBanner from './DomainNotPointingBanner';
import DomainNotRegisteredBanner from './DomainNotRegisteredBanner';

import {
  useHostingV2,
  useResourceMapper,
  useResourceFilters,
  useDomainStatus,
} from '@/composables';
import { useDomainsStatusStore } from '@/stores';
import { Route } from '@/types';
import domainStatusMixin from '@/utils/mixins/domainStatusMixin';

export default {
  name: 'DomainStatusBanner',
  components: {
    DomainNotPointingBanner,
    DomainInactiveBanner,
    DomainNotPaidBanner,
    DomainNotRegisteredBanner,
  },
  emits: ['banner-visibility'],
  mixins: [domainStatusMixin],

  setup() {
    const { isHostingV2 } = useHostingV2();
    const { resourcesWithSubscriptions } = useResourceMapper();
    const { isP2PUser } = useResourceFilters();
    const domainsStatusStore = useDomainsStatusStore();
    const { isLoading: isDomainStatusLoading } = storeToRefs(
      useDomainsStatusStore(),
    );

    return {
      isHostingV2,
      resourcesWithSubscriptions,
      isP2PUser,
      domainsStatusStore,
      isDomainStatusLoading,
    };
  },
  computed: {
    isRouteException() {
      return (
        [Route.Websites.CREATE_REPORT, Route.Websites.CREATING_REPORT].includes(
          this.$route.name,
        ) || this.isHostingV2
      );
    },
    domain() {
      return this.$route.params?.domainToManage || this.$route.params?.domain;
    },
    isHostingAccountUrl() {
      return /\/websites\/(?!migrations)./i.test(this.$route.path);
    },
    isVisible() {
      return (
        this.isHostingAccountUrl &&
        !!this.activeBanner &&
        !this.isDomainPendingSetup &&
        !this.isRouteException
      );
    },
    activeBanner() {
      if (this.domainInactiveStatus) {
        return `domain-${this.domainInactiveStatus}-banner`;
      }

      return '';
    },
    isDomainPendingSetup() {
      return this.resourcesWithSubscriptions.some(
        (resource) =>
          resource.type === HDOMAIN_RESOURCE_TYPES.DOMAIN &&
          resource.state === HDOMAIN_RESOURCE_STATUSES.PENDING_SETUP &&
          resource.title === this.domain,
      );
    },
    ...mapGetters(['getOrdersLoaded']),
  },
  methods: {
    async fetchDomainStatus() {
      if (this.isDomainStatusLoading && !this.domain) {
        return;
      }

      const { domainStatusInputObject } = useDomainStatus(this.domain);

      await this.domainsStatusStore.fetchDomainStatus(
        domainStatusInputObject.value,
        true,
      );
    },
    ...mapActions([
      'domainIsPointingIndex',
      'domainInfoIndex',
      'fetchHostingOrders',
    ]),
  },
  watch: {
    isVisible(visible) {
      this.$emit('banner-visibility', visible);
    },
    '$route.path': {
      immediate: true,
      async handler() {
        if (
          this.isHostingAccountUrl &&
          !this.isRouteException &&
          !this.isP2PUser
        ) {
          if (!this.getOrdersLoaded) {
            await this.fetchHostingOrders();
          }
          this.domainInfoIndex(true);
          await this.domainIsPointingIndex(this.domain);
        }
        this.$emit('banner-visibility', this.isVisible);
      },
    },
    domain: {
      immediate: true,
      async handler() {
        if (
          this.isHostingAccountUrl &&
          !this.isRouteException &&
          !this.isP2PUser
        ) {
          await this.fetchDomainStatus();
        }
        this.$emit('banner-visibility', this.isVisible);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../sass/components/domain-status-banner';
</style>
